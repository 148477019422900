import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Button as AntdButton, Popconfirm, Select, message } from 'antd';
import { Button, Former, LoadingIndicator, SelecionarPublico, usePublico } from '@digi-tim-19/components';
import {
  IncentiveCampaign,
  EnumIncentiveCampaignStatus,
  EnumExternalCampaignStatus,
} from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';
import { Divider, FormContainer, CategoriaMenu, ErrorsModal, Footer, FieldContainer } from '../Cadastrar/styles';
import { useStatus, StatusOptions, CampaignPeriodOptions } from './useStatus';
import moment from 'moment';
import { useInvoiceOriginOptions } from '../useInvoiceOriginOptions';
import { configPage, isWaitingPointsValidityEnd } from './utilsIncentiveCampaign';
import { useContentTypePermissions } from '../../../hooks/useContentTypePermissions';
import { usePagination } from '../../../hooks/usePagination';
import { Boosters } from './Boosters';

export const Form = (props: TFormIncentiveCampaignEditProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [formData, setFormData] = useState<any>(props?.initialValues || {});
  const [isDisabledFields, setIsDisabledFields] = useState(false);
  const [boosters, setBoosters] = useState<any[]>([]);
  const [showExternal, setShowExternal] = useState(!!props.initialValues?.externalCampaign);

  const permissions = useContentTypePermissions();
  const { status } = useStatus();
  const history = useHistory();
  const invoiceOrigins = useInvoiceOriginOptions();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } = usePublico();

  const IncentiveCampaignUpdateOne = useClient('IncentiveCampaignUpdateOne');
  const createIncentiveCampaign = useClient('IncentiveCampaignCreateOne');
  const getLevels = useClient('GameficationNivelFindMany', {
    appendToFragment: `_id title nivel`,
    variables: { limit: 1000 },
    fetchOnMount: true,
  });
  const { parsed } = usePagination('ExternalCampaignPagination', {
    initial: {
      page: 1,
      perPage: 1000,
      filter: { status: EnumExternalCampaignStatus.InProgress },
    },
    itemsFragment: ` _id title `,
    parseResult: (data) =>
      data.map((campaign) => ({
        label: campaign.title,
        value: campaign._id,
      })),
  });

  const pushedState = history.location.state as any;
  const isDuplicate = pushedState?.duplicate;
  const isUpdate = !!props.initialValues && !isDuplicate;
  const validityStart = props.initialValues?.validity?.start;

  const initialValues = useMemo(() => {
    const data = { ...formData };

    if (data?.boosters) {
      setBoosters([...data?.boosters]);
    }

    return {
      ...data,
      _id: isDuplicate ? undefined : data._id,
      participantList: isDuplicate ? [] : data.participantList,
      channel: data?.channel,
      subChannel: data?.subChannel,
      validity: [data?.validity?.start, data?.validity?.end],
      status: isUpdate ? data?.status?._id : EnumIncentiveCampaignStatus.InProgress,
      availableAtRoleGroups: data?.availableAtRoleGroups || [],
      availableAtChannels: data?.availableAtChannels || [],
      externalCampaignFlag: !!data?.externalCampaign,
      externalCampaign: data?.externalCampaign,
      contestationPeriod: isUpdate ? data?.contestationPeriod : '7',
      highlighted: isUpdate ? data.highlighted : true,
      prizeType: data.prizeType,
    };
  }, [formData]); //eslint-disable-line

  const submitEndCampaing = async (record: any) => {
    return IncentiveCampaignUpdateOne.fetch({
      variables: { record, filter: { _id: props?.initialValues?._id } },
    }).then((ctx) => {
      if (!ctx.errors) {
        history.push(configPage.routeList);
      } else {
        console.error('Erro ao atualizar', ctx.errors);
      }
    });
  };

  async function updateCampaing(record: any, _id: string) {
    await IncentiveCampaignUpdateOne.fetch({
      variables: { record, filter: { _id: _id } },
    }).then(async (ctx) => {
      if (!ctx.errors) {
        history.push(configPage.routeList);
      } else {
        setFormData(record);
        console.error('Erro ao atualizar', ctx.errors);
      }
    });
  }

  async function createCampaing(record: any) {
    await createIncentiveCampaign.fetch({ variables: { record } }).then(async (ctx) => {
      if (ctx?.result) {
        message.success(configPage.messageSuccess);
        history.push(configPage.routeList);
      } else {
        setFormData(record);
        console.error('errors', ctx.errors);
        message.error(configPage.messageError);
      }
    });
  }

  useEffect(() => {
    if (
      initialValues.status === EnumIncentiveCampaignStatus.Closed ||
      initialValues.status === EnumIncentiveCampaignStatus.ClosedPartner ||
      initialValues.status === EnumIncentiveCampaignStatus.ClosedWithoutWinners
    ) {
      setIsDisabledFields(true);
    }
  }, [initialValues.status]);

  if (permissions.loading || getLevels.loading) return <LoadingIndicator />;

  return (
    <FormContainer>
      <ErrorsModal
        okText="OK"
        cancelText="SAIR"
        title="Verifique os seguintes campos na planilha"
        visible={isOpen}
        onCancel={() => {
          setIsOpen(false);
          setErrors([]);
        }}
        onOk={() => {
          setIsOpen(false);
          setErrors([]);
        }}
      >
        <div>
          {errors.map((error) => (
            <p>{error}</p>
          ))}
        </div>
      </ErrorsModal>
      <ErrorsModal
        okText="OK"
        cancelText="SAIR"
        title="Deseja realmente encerrar essa campanha?"
        visible={isVerify}
        onCancel={() => {
          setIsVerify(false);
        }}
        onOk={async () => {
          await submitEndCampaing({
            ...formData,
            validity: {
              start: validityStart,
              end: moment(new Date()).endOf('day').subtract(3, 'hours').toISOString(),
            },
          });
        }}
      >
        <p>
          Você está encerrando uma campanha com data de vigência posterior à data de hoje, ao clicar em aceitar a data
          de vigência final será alterada para o dia de hoje.
        </p>
      </ErrorsModal>
      <Former
        initialValues={initialValues}
        config={() => {
          const fields = [
            {
              className: 'header',
              inline: true,
              list: [
                {
                  name: 'schedule',
                  label: 'PROGRAMAR PUBLICAÇÃO',
                  type: 'datePicker',
                  required: true,
                  extraProps: {
                    showTime: true,
                    format: 'DD/MM/YYYY HH:mm:ss',
                  },
                  enableDatePriorToCurrent: true,
                },
                {
                  name: 'validity',
                  label: 'Data de Vigência',
                  type: 'rangePicker',
                  required: true,
                  extraProps: {
                    startPlaceholder: 'Início da vigência',
                    endPlaceholder: 'Fim da vigência',
                  },
                },
                {
                  name: 'videoLink',
                  label: 'LINK PARA O VÍDEO',
                  type: 'text',
                  validate: (value: any) => {
                    if (value) return !(value.length > 1200) ? undefined : 'Máximo de 1200 caracteres';

                    return undefined;
                  },
                },
              ],
            },
            {
              className: 'header header-file-card-banner',
              inline: true,
              list: [
                {
                  name: 'cardImage',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: configPage.kindCardImage,
                    CTA: 'IMAGEM DO CARD',
                    allowedExtensions: ['image/png', 'image/jpeg'],
                  },
                },
                {
                  name: 'bannerContent',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: configPage.kindBannerContent,
                    CTA: 'CONTEÚDO DO BANNER',
                    allowedExtensions: ['image/png', 'image/jpeg'],
                  },
                },
                {
                  name: 'contestationPeriod',
                  label: 'PRAZO PARA CONTESTAÇÃO (DIAS)',
                  type: 'number',
                  required: true,
                  maxWidth: '200px',
                },
                {
                  name: 'externalId',
                  label: 'ID EXTERNO',
                  type: 'number',
                  required: true,
                },
              ],
            },
            {
              name: 'title',
              label: 'TÍTULO DA CAMPANHA',
              type: 'text',
              required: true,
              validate: (value: any) => {
                const format = /[#%&;]/;
                if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                return undefined;
              },
            },
            {
              name: 'description',
              label: 'DESCRIÇÃO DA CAMPANHA',
              type: 'textarea',
              required: true,
              extraProps: {
                rows: 5,
                maxLength: 112,
              },
              validate: (value: any) => {
                const format = /[#%&]/;
                if (format.test(value)) return 'Os caracteres #%& não são permitidos';

                if (value) return !(value.length > 112) ? undefined : 'Máximo de 112 caracteres';

                return undefined;
              },
            },
            {
              custom: <Divider />,
            },
            {
              className: 'header header-file-regulation-presentation',
              inline: true,
              list: [
                {
                  name: 'regulation',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: configPage.kindRegulation,
                    CTA: 'REGULAMENTO',
                    allowedExtensions: ['application/pdf'],
                  },
                },
                {
                  name: 'presentation',
                  label: '',
                  type: 'upload',
                  extraProps: {
                    kind: configPage.kindPresentation,
                    CTA: 'APRESENTAÇÃO',
                  },
                },
                {
                  name: 'invoiceOrigin',
                  label: 'ORIGEM DA FATURA',
                  type: 'select',
                  required: true,
                  options: invoiceOrigins,
                },
                {
                  name: 'prizeType',
                  label: 'TIPO DE PRÊMIO',
                  type: 'select',
                  options: [
                    { value: 'timcoins', label: 'Tim Coins' },
                    { value: 'voucher_multi_cash', label: 'Voucher Multi Cash' },
                    { value: 'voucher_ifood', label: 'Voucher Ifood' },
                    { value: 'viagem', label: 'Viagem' },
                  ],
                },
                {
                  name: 'highlighted',
                  label: 'Exibir no modal da home?',
                  type: 'checkbox',
                },
              ],
            },
            {
              custom: <Divider />,
            },
            {
              className: 'Footer',
              list: [
                {
                  custom: <SelecionarPublico {...permissions.filterOptions} initialValues={initialValues} />,
                },
              ],
            },
            {
              custom: <Divider />,
            },
            {
              inline: true,
              custom: (
                <Boosters
                  options={
                    getLevels?.data?.map((el: any) => {
                      return {
                        label: `${el.nivel} - ${el.title}`,
                        value: el._id,
                      };
                    }) || []
                  }
                  initial={boosters}
                  onChange={setBoosters}
                />
              ),
            },
            {
              inline: true,
              list: [
                {
                  name: 'externalCampaignFlag',
                  label: 'Associar campanha externa?',
                  type: 'checkbox',
                  afterChange: () => setShowExternal((e) => !e),
                },
                showExternal && {
                  name: 'externalCampaign',
                  label: 'Campanha externa',
                  type: 'select',
                  required: true,
                  options: parsed,
                },
              ],
            },
          ];

          return {
            fields,
            customRenderFooter: (renderProps: any) => {
              const { form, invalid } = renderProps;
              const disabled = !showExternal && isDisabledFields;

              return (
                <Footer>
                  <AntdButton onClick={() => history.push(configPage.routeList)}>CANCELAR</AntdButton>
                  <Popconfirm
                    disabled={disabled || invalid}
                    title="Você tem certeza que deseja salvar essa campanha?"
                    onConfirm={() => {
                      form.submit();
                    }}
                    okText="Sim"
                    cancelText="Não, prefiro revisar"
                  >
                    <Button disabled={disabled || invalid}>{isUpdate ? 'Atualizar' : 'Enviar'}</Button>
                  </Popconfirm>
                </Footer>
              );
            },
          };
        }}
        onSubmit={async (obj) => {
          const { data } = obj;

          if (data.status === EnumIncentiveCampaignStatus.Closed) {
            return alert('Não é possivel alterar a campanha encerrada.');
          }

          const startValidity = data.validity[0] || data.validity.start;
          const endValidity = data.validity[1] || data.validity.end;

          const record = {
            boosters: boosters.filter((el) => el?.levelId),
            availableAtRegions,
            availableAtChannels,
            availableAtRoleGroups,
            category: 'campaign',
            title: data.title,
            description: data.description,
            status: data.status,
            schedule: data.schedule,
            videoLink: data.videoLink,
            cardImage: data.cardImage,
            bannerContent: data.bannerContent || undefined,
            contestationPeriod: String(data.contestationPeriod),
            results: status.results,
            ranking: status.preview,
            regulation: data.regulation,
            presentation: data.presentation || undefined,
            invoiceOrigin: data.invoiceOrigin ?? data.externalCampaign ?? undefined,
            validity: { start: startValidity, end: endValidity },
            channel: data.channel,
            subChannel: data.subChannel,
            externalCampaign: data.externalCampaign,
            externalId: String(data.externalId),
            highlighted: data.highlighted,
            prizeType: data.prizeType,
          };

          if (availableAtRegions.length === 0 || availableAtRoleGroups.length === 0) {
            setFormData(record);
            return message.error('Por favor Selecione o público');
          }

          if (isWaitingPointsValidityEnd(record)) {
            setFormData(record);
            return setIsVerify(true);
          }

          if (isUpdate && data._id) {
            await updateCampaing(record, data._id);
          } else {
            await createCampaing(record);
          }
        }}
      />
    </FormContainer>
  );
};
export type TFormIncentiveCampaignEditProps = {
  initialValues?: Partial<IncentiveCampaign>;
};
